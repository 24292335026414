import React from "react"
import styled from "@emotion/styled"
import Layout, { Title } from "../components/layout"
import SEO from "../components/seo"
import { format } from 'date-fns'

const pageTemplate = props => {
  const newsNode = props.pageContext.data

  return (
    <Layout currentPath={null} lang={null} pathEn={null} pathEs={null}>
      <SEO title={`Di No A Las Torres | ${newsNode.title}`} />
      <Header>
        <Title dangerouslySetInnerHTML={{ __html: newsNode.title }} />
        <DateItem>
          {
            format(new Date(newsNode.date), 'dd-MM-yyyy')
          }
        </DateItem>

      </Header>
      <Content dangerouslySetInnerHTML={{ __html: newsNode.content }} />
    </Layout>
  )
}

const Content = styled.div`
  a {
    color: rgb(71, 153, 54);
    &:hover {
      color: rgb(51, 92, 33);
    }
  }
`

const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const DateItem = styled.div`
`

export default pageTemplate
